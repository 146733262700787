<template>
  <div
    v-loading.fullscreen="loading"
    element-loading-spinner="el-icon-loading"
    :element-loading-text=loadingText

  >
 <!-- payment schedule window -->
    <div
      class="grid sm:fixed w-xs justify-self-end grid-cols-1 right-6 sm:z-20"
      v-if="showError == false"
    >
      <transition name="slide-fade" mode="out-in">
        <div v-if="formFields.paymentMethod == 'credit'" :key="formFields.paymentMethod" class="mt-2 border shadow-md text-white rounded text-center p-2 bg-muench bg-opacity-80 lg:w-72">
          <div >
            <p class="my-2 font-medium border-b-1 border-dotted mx-4 pb-2">Paying Now</p>
            <div v-if="formFields.type == 'register'">
              <transition name="slide-fade" mode="out-in">
                <div :key="itemsDisplay" class="text-sm  whitespace-pre-wrap">{{ itemsDisplay }}</div>
              </transition>
              <div class="flex justify-center"><hr class="border-white border-dotted my-2 w-3/4 " /></div>
            </div>
            <div class=" font-medium ">
              Total: <transition name="slide-fade" mode="out-in"><span :key="amountPayingDisplay" class=" font-normal ">{{ amountPayingDisplay }}</span></transition>
            </div>
            <transition name="slide-fade" mode="out-in"><div :key="paymentMethodNote" class="text-xs mb-4">{{ paymentMethodNote }}</div></transition>
          </div>
        </div>
      </transition>
    </div>

  <!-- header -->
  <div>
    <div class="flex justify-center mt-8">
    <div class="flex items-center space-x-8 max-w-4xl p-4 md:w-4/5 text-left">
      <img
        class=" h-24"
        src="../../src/assets/Logo_orange_text-mainlogo.jpg"
        alt="logo"/>
    </div>
    </div>
  <router-view />
  </div>


    <!--PAYMENT MODAL-->
    <el-dialog :title= "paymentModalTitle" :visible.sync="showStripeModal" :width="windowWidth > 600 ? '50%' : '100%'">
      <div class="ml-8 mb-4">
        <p class=" font-semibold pb-2 flex justify-center">Paying Now</p>
        <div v-if="formFields.type =='register'">
          <div class="pl-2 text-sm flex justify-center whitespace-pre-wrap">{{ itemsDisplay }}</div>
          <div class="flex justify-center"><hr class="border-gray-400 border-dotted my-2 w-2/3 " /></div>
        </div>
        <div class=" pl-2 font-semibold flex justify-center space-x-2">
          <span>Total: </span><span class=" font-normal "> {{ amountPayingDisplay }}</span>
        </div>
      </div>
      <!-- <final :formFields="formFields" :rules="rules" ref="final"  v-loading="loading"/> -->
      <final v-if="showStripeModal" :formFields="formFields" :title="this.title" :rules="rules" ref="final" :paymentIntent="paymentIntent" v-loading="loading"/>
      <span slot="footer" class="dialog-footer">
      </span>
    </el-dialog>
  
    <!-- main window -->
    <div class="flex justify-center " v-if="showError == false">
      <div
        class="border max-w-4xl p-4 rounded shadow-md mb-4 w-7/8 md:w-4/5 text-left bg-white z-10 "
      >
        <p class="muench font-bold text-3xl pl-2">Payments</p>
        <p class="text-lg pl-3" >
          If you need to make a payment to Muench Workshops by credit card, please fill out this form.
        </p>
        <hr class="border border-dotted mb-4" />

        <el-form
          :model="formFields"
          :rules="rules"
          ref="formFields"
          class="w-full mt-2 text-left space-y-2"
        >
          <!-- Main Selection - Visible on both Payment and Register routes-->
          <div class="flex justify-center">
            <div class="block w-full lg:mx-8 space-y-2">
              <p class="font-medium pl-2">
                Name <span class="text-red-500">*</span>
              </p>
              <div class="flex w-full lg:flex-row flex-col lg:space-x-2">
                <el-form-item prop="fname" class="lg:w-1/2">
                  <el-input
                    placeholder="First"
                    v-model="formFields.fname"
                  ></el-input>
                </el-form-item>

                <el-form-item prop="lname" class="block lg:w-1/2">
                  <el-input
                    placeholder="Last"
                    v-model="formFields.lname"
                  ></el-input>
                </el-form-item>
              </div>

              <div>
                <p class="font-medium pl-2">
                  Email <span class="text-red-500">*</span>
                </p>
                <el-form-item prop="email">
                  <el-input
                    placeholder="Email"
                    v-model="formFields.email"
                  ></el-input>
                </el-form-item>
              </div>

            

              <!--EXISTING REGISTRATION-->
              <div>
                <div class="flex items-end">
                  <p class="font-medium pl-2 mb-3">
                    Address <span class="text-red-500">*</span>
                  </p>
                </div>
                <div class="space-y-1">
                  <el-form-item prop="billingAddr1">
                    <el-input
                      placeholder="Street Address"
                      v-model="formFields.billingAddr1"
                    ></el-input
                  ></el-form-item>

                  <el-form-item prop="billingAddr2">
                    <el-input
                      placeholder="Address Line 2"
                      v-model="formFields.billingAddr2"
                    ></el-input
                  ></el-form-item>

                  <div class="flex space-x-2 pt-4">
                    <el-form-item prop="billingCity" class="w-1/2">
                      <el-input placeholder="City" v-model="formFields.billingCity">
                      </el-input>
                    </el-form-item>

                    <el-form-item prop="billingState" class="w-1/2">
                      <el-input
                        placeholder="State/Province"
                        v-model="formFields.billingState"
                      ></el-input>
                    </el-form-item>
                  </div>

                  <div class="flex space-x-2 w-full">
                    <el-form-item prop="billingZip" class=" w-1/2">
                      <el-input
                        placeholder="Postal/Zip Code"
                        v-model="formFields.billingZip"
                      ></el-input>
                    </el-form-item>

                    <el-form-item prop="billingCountry" class=" w-1/2">
                      <el-autocomplete
                        v-model="formFields.billingCountry"
                        class="inline-input w-full"
                        :fetch-suggestions="querySearch"
                        placeholder="Country"
                      ></el-autocomplete>
                    </el-form-item>
                  </div>
                </div>

                <div class="flex mt-3 ">
                  <div class="w-full">
                    <p class="font-medium pl-2">Amount to Pay <span class="text-red-500"> *</span></p>
                    <el-form-item prop="differentDollar"
                      ><el-input
                        v-mask="currencyMask"
                        v-model="formFields.differentDollar"
                        placeholder="$USD"
                      ></el-input>
                    </el-form-item>
                    <p class="font-medium pl-2">Description (tell us what the payment is for)<span class="text-red-500"> *</span></p>
                    <el-form-item prop="differentNote">
                      <el-input
                      v-model="formFields.differentNote"
                      type="textarea"
                      rows="2" class="mb-2 w-full"
                    ></el-input>
                    </el-form-item>
                  </div>
                  
                </div>
                <div class="space-y-2">
                  <div class="flex w-full justify-center py-4">
                    <el-button
                        type="primary"
                        @click="openPaymentValidate('formFields')"
                        >Make Payment</el-button
                      >
                  </div>
                </div>
              </div>
              <!-- <div  v-else-if="formFields.showError == true">
                <hr class="border border-dotted mt-4" />
                <div class="flex justify-center text-sm text-gray-300 mt-4">
                  <p>An error occurred.</p>
                </div>
              </div> -->
              
            </div>
          </div>
        </el-form>
      </div>
    </div>
    <!--Error Messages-->
    <div v-else class="flex justify-center ">
      <div
        class="border pl-4 pr-10 py-20 rounded shadow-md mb-4 w-7/8 md:w-4/5 text-center bg-white z-10 italic font-light whitespace-pre-wrap"
      >
        {{ showErrorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
// import loginAPI from "@/api/LoginAPI.js";
import sharedAPI from "@/api/SharedAPI.js";
import store from "@/store.js";
import countries from "countries-list";
import final from '@/components/final'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const currencyMask = createNumberMask({
    prefix: '$',
    allowDecimal: true,
    includeThousandsSeparator: true,
    allowNegative: false,
  });

export default {
  name: "GenericPaymentForm",
  components: {
    'final': final
  },
  data() {
    return {
      currencyMask,
      formFields: {
        genericPayment: true,
        billingAddr1: "",
        billingAddr2: "",
        billingCity: "",
        billingCountry: "",
        billingState: "",
        billingZip: "",
        cell: "",
        cellPrefix: "",
        differentDollar: null,
        differentNote: "",
        email: "",
        fname: "",
        isCellIntl: null,
        isPayingOther: null,
        isPayingOtherNote: "",
        isPayingSecondPayment: null,
        isPayingSingle: null,
        lname: "",
      },
      amountPaying: "",
      countryList: [],
      countryPhoneList: [],
      delayTimer: "",
      loading: false,
      loadingEmail: false,
      loadingText: "Building your form...",
      paymentIntent:{
        param: "",
        code: "",
        type: "",
        status:"",
        clientSecret:"",
      },
      showError: false,
      showErrorMessage: "",
      showStripeModal: null,
      stripe: {},
      stripeSession: {},
      subtitle: "",
      title: "",
      token: "",
      tokenTime: "",
      windowWidth: window.innerWidth,
      rules: {
        fname: [
          {
            required: true,
            message: "First name is required",
            trigger: "blur",
          },
        ],
        lname: [
          { required: true, message: "Last name is required", trigger: "blur" },
        ],
        billingAddr1: [
          { required: true, message: "Address is required", trigger: "blur" },
        ],
        billingAddr2: [{ required: false }],
        billingCity: [
          { required: true, message: "City is required", trigger: "blur" },
        ],
        billingState: [
          {
            required: true,
            message: "State/Province is required",
            trigger: "blur",
          },
        ],
        billingZip: [
          {
            required: true,
            message: "Postal/Zip code is required",
            trigger: "blur",
          },
        ],
        billingCountry: [
          { required: true, message: "Country  is required", trigger: "change" },
        ],
        email: [
          { required: true, message: "Email is required", trigger: "blur" },
        ],
        differentNote: [
          { required: true, message: "Description is required", trigger: "blur" },
        ],
        cell: [
          {
            required: true,
            message: "A contact number is required",
            trigger: "blur",
          },
        ],
        differentDollar: [
          { required: true, message: "Enter an amount", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.loadData();
    document.title = 'Payments Form'
  },
  computed: {
    amountPayingDisplay: function() {
      return this.toCurrency(this.amountPaying);
    },
    paymentModalTitle: function() {
      return "Make Payment: " + this.amountPayingDisplay;
    },
    totalDisplay: function() {
      return this.toCurrency(this.total);
    },
    otherDisplay: function() {
      return this.toCurrency(this.formFields.differentDollar.replace(/[&\/\\,$]/g, ''));
    },
    paymentMethodNote() {
      if (this.formFields.paymentMethod == "check") {
        return "You will receive payment instructions by email.";
      } else if (this.formFields.paymentMethod == "credit") {
        return "Will be charged to your credit card.";
      } else if (this.formFields.paymentMethod == "other") {
        return "Please note the special arrangements below.";
      }
    },
  },
  watch: {
    "formFields.email": {
      handler: "validEmail",
    },
    "formFields.differentDollar"() {
      if (this.formFields.differentDollar != "") {
        this.amountPaying = this.formFields.differentDollar.replace(/[&\/\\,$]/g, '');
      }
    },
    "formFields.billingCountry"() {
      if (this.formFields.billingCountry.toLowerCase() === "usa") {
        this.formFields.billingCountry = "United States"
      }
    },
  },

  methods: {
    async setConnection() {
      var DOM = this;
      DOM.loading = true;
      var axios = require("axios");
      var data = JSON.stringify({});

      var config = {
        method: "post",
        url: "proxy.php?/databases/FM_FILE/sessions",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa("FM_USER:FM_PASSWORD"),
        },
        data: data,
      };
      var token;
      await axios(config)
        .then(function(response) {
          token = JSON.stringify(response.data.token);
          //trim quotes from string
          token = token.slice(1);
          token = token.slice(0, -1);
          DOM.token = token;
          DOM.tokenTime = new Date().getTime();
          DOM.loading = false;
          store.commit("setToken", token);
        })
        .catch(function(error) {
          console.log(error);
          DOM.loading = false;
        });
    },
    /**
     * Loads list of staffs for select using GraphQL
     */
    async loadData() {
      var DOM = this;
      document.title = "Muench Workshops";
      DOM.formFields.paymentMethod = 'credit'
      DOM.formFields.type = 'pay'
      DOM.formFields.isPayingOther = true
      DOM.stripe = require('stripe')('pk_live_bR2dqISTvS3FKsFvmhgc5R0C');

      this.makeCountryLists(DOM);
      
    },

    toCurrency(amt) {
      var formatCurrency = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatCurrency.format(amt);
    },
    handleAmountUpdate() {
      this.amountPaying = this.formFields.otherAmount;
    },
    makeCountryLists(DOM) {
      //build country list
      var data = countries.countries;
      for (var i in data) {
        var key = i;
        var val = data[key];
        var label = val.name;
        var value = val.name;
          if(value != "United States") {
            DOM.countryList.push({ value: value, label: label, link: label });
          }
      }

      DOM.countryList.sort();
      // sort by name
      DOM.countryList.sort(function(a, b) {
        var nameA = a.label.toUpperCase(); // ignore upper and lowercase
        var nameB = b.label.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

        // add US to top 
      DOM.countryList.unshift({ value: "United States", label: "United States", link: "United States" });

      //build phone list
      for (var i in data) {
        var key = i;
        var val = data[key];
        if (val.phone.indexOf(",") != -1) {
          //comma found
          //find all commas
          //push a value for the beginning
          var indices = [];
          indices.push(0);
          for (var j = 0; j < val.phone.length; j++) {
            if (val.phone[j] === ",") {
              indices.push(j);
            }
          }
          indices.push(val.phone.length);
          var commas = Object.keys(indices).length;
          //push a prefix for each occurence
          for (var j = 0; j < commas - 1; j++) {
            if (j == 0) {
              var phone = val.phone.substring(indices[j], indices[j + 1]);
            } else {
              var phone = val.phone.substring(indices[j] + 1, indices[j + 1]);
            }
            var label = val.emoji + "    " + val.name + " +" + phone;
            var value = phone;
            DOM.countryPhoneList.push({
              value: label,
              label: label,
              name: val.name,
            });
          }
        } else {
          //no comma
          var label = val.emoji + "    " + val.name + " +" + val.phone;
          var value = val.phone;
          DOM.countryPhoneList.push({
            value: label,
            label: label,
            name: val.name,
          });
        }
        DOM.countryPhoneList.sort();
        // sort by name
        DOM.countryPhoneList.sort(function(a, b) {
          var nameA = a.name.toUpperCase(); // ignore upper and lowercase
          var nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      }
    },
    async openPaymentValidate(formFields){
      var DOM = this;
      DOM.loadingText = "Verifying..."
      var pageData = this.formFields;
      pageData.title = this.title;
      pageData.token = this.token;
      pageData.amount = this.amountPaying;


      await DOM.$refs[formFields].validate((valid) => {
        if (pageData.type == 'pay' && (pageData.amount < 1 || !pageData.amount )){
          this.$message({
            message: "The amount being paid must be above $1 USD.",
            type: 'error',
            duration: 6000
          });
          return false
        }
        if (!valid) {
          DOM.$message({
            message: "Please fill in all required fields.",
            type: 'error',
            duration: 6000
          });
          DOM.markInvalid()
          return false
        }
        if(valid){
          DOM.openPayment()
        }
        return
      });

    },

    async openPayment(){
      this.loading = true
      var DOM = this;
      DOM.loadingText = "Verifying..."
      var pageData = this.formFields;
      pageData.title = this.title;
      pageData.token = this.token;
      pageData.amount = this.amountPaying;


      try{
        var query= [{ 
          FM_WS_F: this.formFields.form, 
          token: this.formFields.token 
        }]
        if(this.formFields.genericPayment) {
          var metadata = {
            Name: this.formFields.fname + " " + this.formFields.lname,
            GenericPayment: true,
            Workshop: this.formFields.differentNote
          }
          var title = this.formFields.differentNote
        } else {
          var metadata = {
            Name: this.formFields.fname + " " + this.formFields.lname,
            WorkshopID: this.formFields.id,
            Workshop: this.title,
          }
          var title = this.title
        }
        var items = [{ 
          cabin: this.formFields.selectedCabin,
          supplement: this.formFields.isPayingSingle,
          deposit: this.formFields.isPayingDeposit,
          second: this.formFields.isPayingSecondPayment,
          balance: this.formFields.isPayingBalance,
          other: this.formFields.isPayingOther,
          otherAmount: this.formFields.differentDollar.replace(/[&\/\\,$]/g, ''),
          email: this.formFields.email,
          title: title,
          customer: this.formFields.fname + " " + this.formFields.lname,
          data: metadata,
          customer: {
            address: {
              line1: this.formFields.addr1,
              line2: this.formFields.addr2,
              city: this.formFields.city,
              state: this.formFields.state,
              postal_code: this.formFields.zip,
            },
            email: this.formFields.email,
            name: this.formFields.fname + " " + this.formFields.lname
          }
        }]
        var data = JSON.stringify({
          items, query
        });
        var result = await sharedAPI.create(data);
        if(!result.data.clientSecret || result.data.error){
          let e = result.data.error ? result.data.error : "missing client_secret"
          throw e
        }
        this.paymentIntent.clientSecret =  result.data.clientSecret
        this.paymentIntent.status =  result.data.status
        this.paymentIntent.type =  result.data.type
        this.paymentIntent.code =  result.data.code
        this.paymentIntent.param =  result.data.param
        this.showStripeModal = true
      }
      catch(e){
        console.log(e)
        var message = await this.displayUserError(e)
        DOM.$message( {
          message: `Could not generate payment intent. Error: ${message}. Please try again.`,
          type: 'error',
          duration: 6000
        })
        let params = {
          error: e,
          workshopDetails: this.formFields
        }
        sharedAPI.runScript('WebLog-SendErrorEmail', JSON.stringify(params))
      }
      finally{
        this.loading = false
      }
    },
    // async openPayment(formFields){
    //   if (this.token == "ul") {
    //     await this.setConnection();
    //   }
    //   if (this.token == null) {
    //     return;
    //   }
    //   var DOM = this;
    //   DOM.loadingText = "Verifying..."
    //   var now = new Date().getTime();
    //   var diff =  Math.floor(now - this.tokenTime / 1000 / 60)
    //   if (diff >= 15){
    //     await DOM.setConnection()
    //   }
    //   var pageData = this.formFields;
    //   pageData.title = this.title;
    //   pageData.token = this.token;
    //   pageData.amount = this.amountPaying;

    //   DOM.$refs[formFields].validate((valid) => {
    //     if (pageData.type == 'pay' && (pageData.amount < 1 || !pageData.amount )){
    //       this.$message.error("The amount being paid must be above $1 USD.");
    //       return
    //     }
    //     if (valid) {
    //       DOM.showStripeModal = true
    //     } else {
    //       DOM.$message.error("Please fill in all required fields.");
    //     }
    //   });
    // },
    querySearch(queryString, cb) {
      var links = this.countryList;
      var results = queryString ? links.filter(this.createFilter(queryString)) : links;
      // call callback function to return suggestions
      cb(results);
    },
    createFilter(queryString) {
      return (link) => {
        return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    toCurrency(amt) {
      var formatCurrency = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatCurrency.format(amt);
    },
    validEmail: function(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  }
};
</script>
<style scoped>
textarea {
  padding-top: 10px;
}
.invalid {
  border: #f56c6c 1px solid;
  border-radius: 4px;
}
.el-select,
.el-date-editor {
  width: 100% !important;
}


</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/payments',
    name: 'Payments',
    component: Home
  },
  {
    path: '/confirmation',
    name: 'confirmation',
    component: () => import(/* webpackChunkName: "Confirmation" */ '../views/Confirmation'),
    props: true
  
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: () => import(/* webpackChunkName: "Feedback" */ '../views/Feedback'),
  
  },
  {
    path: '/:code?',
    name: 'workshop',
    component: () => import(/* webpackChunkName: "Workshop" */ '../views/Workshop'),
    props: true
  
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

/*****************
 2019-2-13, AJK, added state, getters, and mutations for login
 2019-2-27, AJK, added state for campaignAndEmails, Emails
******************/


import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persist'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    indquiryChartData: {},
    userInfo: {},
    existingPassword: "",
    token:{}
  },
  getters: {
    isLoggedIn: state => {
      return state.userInfo.loggedIn;
    },
    isPasswordChangeNeeded: state =>{
      return state.userInfo.isPasswordChangeNeeded;
    }
  },
  mutations: {
    setUserData(state, payload) {
      state.userInfo = payload;
    },
    setCampaigns(state, payload) {
      state.campaigns = payload;
    },
    setEmails(state, payload) {
      state.emails = payload;
    },
    setToken(state, payload) {
      state.token = payload;
    },
    setCampaignsAndEmails(state, payload) {
      state.campaignsAndEmails = payload;
    },
    setSelectedCampaign(state, payload) {
      state.selectedCampaign = payload;
    },
    setCart(state, payload){
      state.cart = payload;
    },   
    setExistingPassword(state, payload) {
      state.existingPassword = payload;
    }
  },
  actions: {

  },

  plugins: [
    new createPersistedState({
      paths: ['userInfo', 'campaigns', 'selectedCampaign', 'emails', 'campaignsAndEmails', 'cart']
    }).plugin
  ],

  methods: {
      test: function (){
          return 1;
      }
  }
})

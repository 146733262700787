/*****************
 2019-2-14, AJK, created file
******************/


import axios from 'axios';


export default {
    create: function(params){
        return axios.post(`create.php`, params)
        .then((response) => {
            //success callback
            return response
        })
        .catch((e) =>{
            return e
        })
    },
    downloadDocument: function(documentID){
        return axios.post(`downloadDocument.php`, documentID)
        .then((response) => {
            // success callback
            return response.data
        })
        .catch( (e) => {
            if(e.response.data.messages[0].message){
                //Return FM Error Response
                return e.response.data
            }
            else{
                //Format Unknown Error Like a FM Error
                return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
            }
        })
    },
    getPerson: function(params){
        return axios.post(`getPerson.php`, params)
        .then((response) => {
            //success callback
            return response
        })
        .catch((e) =>{
            //Format Unknown Error Like a FM Error
            return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
        })
    },
    getWorkshop: function(params){
        return axios.post(`getWorkshop.php`, params)
        .then((response) => {
            //success callback
            return response
        })
        .catch((e) =>{
            //Format Unknown Error Like a FM Error
            return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
        })
    },
    getPastWorkshop: function(params){
        return axios.post(`getPastWorkshop.php`, params)
        .then((response) => {
            //success callback
            return response
        })
        .catch((e) =>{
            //Format Unknown Error Like a FM Error
            return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
        })
    },
    runScript: function(script, parameters) {
        const postData = {
            fieldData : {
                postData: parameters
            },
            script : script,
            'script.param' : parameters
        }
        return axios.post(`runScript.php`, postData)
            .then((response)=>{
                return response.data
            })
            .catch((e)=>{
                if(e.response.data.messages[0].message){
                    //Return FM Error Response
                    return e.response.data
                }else{
                    //Format Unknown Error Like a FM Error
                    return  `{ messages:[message: ${e.message}, code: -1],response: {}}`;
                }
            })
    },
    submitPayment: function(params){
        return axios.post(`submitPayment.php`, params)
        .then((response) =>{
            //success callback
            return response
        })
        .catch((e) =>{
            //Format Unknown Error Like a FM Error
            return  `{ messages:[message: ${e.message}, code: -1],response: {}}`            
        })
    },
    submitFeedback: function(params){
        return axios.post(`submitFeedback.php`, params)
        .then((response) =>{
            //success callback
            return response
        })
        .catch((e) =>{
            //Format Unknown Error Like a FM Error
            return  `{ messages:[message: ${e.message}, code: -1],response: {}}`            
        })
    },
    submitRegistration: function(params){
        return axios.post(`submitRegistration.php`, params)
        .then((response) =>{
            //success callback
            return response
        })
        .catch((e) =>{
            //Format Unknown Error Like a FM Error
            return  `{ messages:[message: ${e.message}, code: -1],response: {}}`            
        })
    }
}
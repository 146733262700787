import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import Element from 'element-ui';
import VueMask from 'v-mask';
import "tailwindcss/tailwind.css";
import locale from 'element-ui/lib/locale/lang/en';
import './element-variables.scss'
// import VueGtag from "vue-gtag";
import VueGtm from '@gtm-support/vue2-gtm';


Vue.use(Element, {locale})
Vue.config.productionTip = false
// Vue.use(ElementUI, { locale })
Vue.use(VueMask);
// Vue.use(VueGtag, {
//   config: { id: "UA-34230132-1" },
//   includes: [
//     { id: 'G-V7B9MQ2R3X' },
//   ]
// },router);
Vue.use(VueGtm, {
  id: 'GTM-W999WH8',
  defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  nonce: '2726c7f26c', // Will add `nonce` to the script tag
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
});


//---------AXIOS Setup------------//
window.axios = require("axios");
if (localStorage.sessionId == undefined) {
  localStorage.sessionId = Math.random()
    .toString(36)
    .substring(2);
}
var sessionId = localStorage.sessionId;
if(window.location.hostname == 'localhost'){
  axios.defaults.baseURL = 'http://localhost:8888/muench-wis-payment/api/v1/'
}
else{
  axios.defaults.baseURL = window.location.protocol + "//" + window.location.hostname + "/api/v1/";
}
axios.defaults.headers.common['Session-Id'] = sessionId ;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.patch['Content-Type'] = 'application/json'


Vue.mixin({
  methods:{
    displayUserError(e){
      var message = ''
      try{
		if(e.displayError) message = e.displayError
        else if(e.errors) message = e.errors[0].message
        else if(e.message) message = e.message
        else if(e.data) message = e.data.message
        else message = e
      }
      catch(syntaxError){
        message = e
      }
  
      return message
    }
  }

})



new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
